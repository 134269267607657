import {
  PlanTypeEnum,
  useOrganisationQuery,
  useProfileQuery,
} from 'generated/__generated_graphql'
import { AppProvider } from 'providers/app-provider'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultLayout from './components/layouts/default-layout'
import productDashboardRoutes from './routes/product-dashboard-routes'
import serviceDashboardRoutes from './routes/service-dashboard-routes'
import AtlasAi from 'pages/atlas-ai/atlas-ai'

const customerTypeRoutes = {
  [PlanTypeEnum.Product]: productDashboardRoutes,
  [PlanTypeEnum.Service]: serviceDashboardRoutes,
}

function getRoutes(customer: keyof typeof customerTypeRoutes) {
  return customerTypeRoutes[customer].map(
    (
      {
        element: Element,
        path,
        isIndex,
        provider: Provider,
        layout: CustomLayout,
        layoutProps,
      },
      index
    ) => {
      const Layout = CustomLayout ?? DefaultLayout

      if (Provider) {
        return (
          <Route
            key={index}
            index={isIndex}
            path={path as string}
            element={
              <Provider>
                <Layout {...layoutProps}>
                  <Element />
                </Layout>
              </Provider>
            }
          ></Route>
        )
      }

      return (
        <Route
          key={index}
          index={isIndex}
          path={path as string}
          element={
            <Layout {...layoutProps}>
              <Element />
            </Layout>
          }
        ></Route>
      )
    }
  )
}

export default function Dashboard() {
  const [{ data: orgQuery }] = useOrganisationQuery()
  const [{ data: profileQuery }] = useProfileQuery()
  const { planType } = orgQuery?.organisation?.plan ?? {}

  const { firstName, email } = profileQuery?.profile ?? {}

  useEffect(() => {
    // @ts-expect-error
    if (window.fcWidget) {
      // To set user name
      // @ts-expect-error
      window.fcWidget.user.setFirstName(firstName)

      // To set user email
      // @ts-expect-error
      window.fcWidget.user.setEmail(email)
    }
  }, [])

  return (
    <AppProvider>
      <Routes>
        {getRoutes(
          planType === PlanTypeEnum.Product
            ? PlanTypeEnum.Product
            : PlanTypeEnum.Service
        )}
        <Route key={'index'} path={'/ai'} element={<AtlasAi />}></Route>
      </Routes>
    </AppProvider>
  )
}
