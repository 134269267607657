// import Input from 'components/input/input'
// import { Avatar } from 'components/avatar/avatar'
// import discountImg from 'assets/png/discount.png'
// import { FiSearch } from 'react-icons/fi'
// import { Avatar } from 'components/avatar/avatar'
import { ReactComponent as AccounteerLogo } from 'assets/svg/logo-white.svg'
import AccountSwitcher from 'components/account-switcher/account-switcher'
// import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import MenuBar from 'components/menu-bar/menu-bar'
// import QuickFind from 'components/quick-find/quick-find'
// import Tag from 'components/tag/tag'
// import { Text } from 'components/text/text'
import { motion } from 'framer-motion'
import {
  HiCash,
  HiClipboardCheck,
  // HiGift,
  HiHome,
  HiOutlineCash,
  HiOutlineClipboardCheck,
  // HiOutlineGift,
  HiOutlineHome,
  // HiOutlinePresentationChartLine,
  HiOutlineReceiptRefund,
  HiOutlineUsers,
  // HiPresentationChartLine,
  HiReceiptRefund,
  HiUsers,
} from 'react-icons/hi'
// import { MdAccountTree, MdOutlineAccountTree } from 'react-icons/md'
import { BiPurchaseTag, BiSolidPurchaseTag } from 'react-icons/bi'
// import { useNavigate } from 'react-router-dom'
import { DashboardLayoutProps } from './layout.types'
import { StyledCol, StyledRow } from './layouts.styles'
import { useNavigate } from 'react-router-dom'
import AIimage from '../../../../assets/png/ai.png'

const defaultLayoutItems = [
  {
    key: 'dashboard',
    icon: <HiOutlineHome />,
    iconActive: <HiHome />,
    label: 'Dashboard',
    path: '/dashboard',
  },

  {
    key: 'bills',
    icon: <HiOutlineCash />,
    iconActive: <HiCash />,
    label: 'Bills',
    path: '/dashboard/purchases/expenses',
  },
  {
    key: 'purchases',
    icon: <BiPurchaseTag />,
    iconActive: <BiSolidPurchaseTag />,
    label: 'Purchase Orders',
    path: '/dashboard/purchases/orders',
  },
  // {
  //   key: 'invoices',
  //   icon: <HiOutlineChartPie />,
  //   iconActive: <HiChartPie />,
  //   label: 'Invoices',
  //   path: '/dashboard/sales/invoices',
  // },
  {
    key: 'Suppliers',
    icon: <HiOutlineUsers />,
    iconActive: <HiUsers />,
    label: 'Suppliers',
    path: '/dashboard/contacts',
  },

  {
    key: 'inventory',
    icon: <HiOutlineClipboardCheck />,
    iconActive: <HiClipboardCheck />,
    label: 'Inventory',
    path: '/dashboard/inventory/items',
  },

  {
    key: 'taxes',
    icon: <HiOutlineReceiptRefund />,
    iconActive: <HiReceiptRefund />,
    label: 'Taxes',
    path: '/dashboard/taxes/tax-rates',
  },
]

const sidebarWidth = 270

const DefaultLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children } = props
  const navigate = useNavigate()

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        },
      }}
      exit={{ opacity: 0, scale: 1.2 }}
    >
      <StyledRow>
        <StyledCol css={{ width: sidebarWidth, background: '#333' }}>
          <Flex
            direction="column"
            justify="start"
            gutter="5"
            css={{ p: '$3', height: '100%' }}
          >
            <AccounteerLogo
              style={{
                width: '8rem',
              }}
            />
            <Flex
              align="center"
              gutter="2"
              css={{
                position: 'absolute',
                top: '6rem',
                left: '$3',
                width: sidebarWidth - 32,
                backgroundColor: '#fff',
                p: '$2 ',
                borderRadius: '1rem',
                cursor: 'pointer',
                fontSize: '1.4rem',
                color: 'black',

                img: {
                  height: '3rem',
                  animation: `rotate360 10s linear infinite`,
                },
              }}
              onClick={() => navigate('/dashboard/ai')}
            >
              <img src={AIimage} alt="" />
              Copilot
            </Flex>
            <MenuBar data={defaultLayoutItems} />
            <Box
              css={{
                position: 'absolute',
                bottom: '3rem',
                left: '$3',
                width: sidebarWidth - 32,
                // backgroundColor: '#444B30',
                // p: '$3',
                borderRadius: '1rem',
              }}
            >
              <AccountSwitcher />
            </Box>
          </Flex>
        </StyledCol>
        <StyledCol
          css={{
            width: `calc(100vw - ${sidebarWidth}px)`,
            position: 'relative',
            padding: '2rem 20rem',
          }}
        >
          {children}
        </StyledCol>
      </StyledRow>
    </motion.div>
  )
}

export default DefaultLayout
