import React from 'react'
import { StyledSequence } from './atlas-ai.styled'
import TypeAheadSelect from 'components/type-ahead-select'
import { Flex } from 'components/layout'
import Button from 'components/button/button'
import SequenceBg from '../../assets/png/sequence-bg.png'

export default function AtlasAiSequence({
  setShowSequence,
  handleSaveAutomation,
}: {
  setShowSequence: any
  handleSaveAutomation: any
}) {
  const [step, setStep] = React.useState(0)
  return (
    <StyledSequence>
      <img className="sequence-bg" src={SequenceBg} alt="" />
      <h5>Add alert</h5>
      <Flex direction="column">
        <TypeAheadSelect
          className="select-first"
          required
          isSearchable
          placeholder="Select block"
          options={[
            {
              label: 'Notify me if no response received within 48 hours',
              value: 'ISO',
            },
            {
              label: 'Notify if quote is within budget range',
              value: 'BSCI',
            },
            {
              label: 'Notify if no response after 48 hours',
              value: 'AEO',
            },
          ]}
          onChange={(value) => {
            setStep(1)
          }}
        />
        <Flex direction="column" className="flow">
          {step >= 1 && (
            <TypeAheadSelect
              className="select"
              required
              isSearchable
              placeholder="Choose notification method"
              options={[
                {
                  label: 'Slack',
                  value: 'ISO',
                },
                {
                  label: 'WhatsApp',
                  value: 'BSCI',
                },
                {
                  label: 'SMS',
                  value: 'AEO',
                },
              ]}
              onChange={(value) => {
                setStep(2)
              }}
            />
          )}
          {step >= 2 && (
            <TypeAheadSelect
              className="select line"
              required
              isSearchable
              placeholder="Configure follow-up actions"
              options={[
                {
                  label: 'If quote is favorable, prepare PO draft',
                  value: 'ISO',
                },
                {
                  label: 'If quote is high, request negotiation',
                  value: 'BSCI',
                },
                {
                  label: 'Set reminder to compare with other quotes',
                  value: 'BSCI',
                },
              ]}
              onChange={(value) => {
                setStep(3)
              }}
            />
          )}
        </Flex>
      </Flex>

      <Flex align="center" justify="between" className="sequence-footer">
        <Button appearance="secondary" onClick={() => setShowSequence(false)}>
          Back
        </Button>
        <Button onClick={handleSaveAutomation}>Save automation</Button>
      </Flex>
    </StyledSequence>
  )
}
