import { Link } from 'react-router-dom'
import { styled } from 'stitches/stitches.config'

export const StyledWrapper = styled('div', {
  position: 'relative',
})
export const StyledDropdown = styled('div', {
  // background: '$white',
  borderRadius: '$3',
})
export const StyledContent = styled('div', {
  position: 'absolute',
  zIndex: 1000,
  bottom: '110%',
  left: '10%',
  width: '30rem',
  background: '$white',
  borderRadius: '$3',
  border: '$1 solid $border',
  filter: 'drop-shadow(0 .8rem 2rem rgb(0 0 0 / 8%))',

  // overflowY: 'hidden',
  // overflowX: 'hidden',
  pointerEvents: 'none',
  opacity: 0,
  backgroundColor: '$white',
  transform: 'translateY(-1.5rem)',
  transition: 'opacity 0.25s 0.1s, transform 0.25s 0.1s, filter 0.2ms 0.1ms',

  '.hover': {
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    padding: '$2',

    '&:hover': {
      background: '$highlightBg',
      borderRadius: '$2',
    },
  },

  variants: {
    open: {
      true: {
        zIndex: 1000,
        opacity: 1,
        pointerEvents: 'auto',
        overflowY: 'auto',
        transform: 'translateY(0)',
      },
    },
  },
})

export const StyledLink = styled(Link, {
  textDecoration: 'none',
})
