import React, { useState, useEffect, useRef } from 'react'
import { StyleContent, StyledButton, StyledUpload } from './atlas-ai.styled'
import AIimage from '../../assets/png/ai.png'
import favicon from '../../assets/svg/favicon.svg'
import { Flex, Stack } from 'components/layout'
import { TbSend } from 'react-icons/tb'
import { AIresponse } from './atlas-ai-response.data'
import { motion } from 'framer-motion'
import { RiCloseLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { PiMagicWandBold } from 'react-icons/pi'
import AtlasAiSequence from './atlas-ai-sequence'
import { BsFileEarmarkPdfFill } from 'react-icons/bs'

type MessageType = {
  id: number
  type: 'user' | 'ai'
  message: string
  messageType: 'text' | 'file'
  actions?: string[]
}

export default function AtlasAi() {
  const [messages, setMessages] = useState<MessageType[]>([])
  const [inputValue, setInputValue] = useState('')
  const [aiResponseIndex, setAiResponseIndex] = useState(0)
  const [isTyping, setIsTyping] = useState(false) // State for typing indicator
  const messagesEndRef = useRef<HTMLDivElement | null>(null) // Ref for scrolling
  const [showSequence, setShowSequence] = React.useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const navigate = useNavigate()

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages, showSequence])

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (inputValue.trim() === '') return

    const newMessage: MessageType = {
      id: messages.length + 1,
      type: 'user',
      messageType: 'text',
      message: inputValue,
    }

    setMessages([...messages, newMessage])
    setInputValue('')

    // Add the next AI response
    addNextAIResponse()
  }

  // Function to handle action button clicks
  const handleActionClick = (text: string) => {
    const newMessage: MessageType = {
      id: messages.length + 1,
      type: 'user',
      messageType: 'text',
      message: text,
    }

    setMessages([...messages, newMessage])

    // Add the next AI response
    addNextAIResponse()
  }

  // Function to add the next AI response in sequence
  const addNextAIResponse = React.useCallback(
    (payload?: { automationMessage?: string; actions?: string[] }) => {
      setIsTyping(true) // Show typing indicator
      setTimeout(() => {
        setMessages((prevMessages) => {
          if (aiResponseIndex < AIresponse.length) {
            const nextResponse = AIresponse[aiResponseIndex]
            const newAIMessage: MessageType = {
              id: prevMessages.length + 1,
              type: 'ai',
              messageType: 'text',
              message: payload?.automationMessage || nextResponse.message,
              actions: payload?.actions || nextResponse.actions,
            }
            return [...prevMessages, newAIMessage]
          }
          return prevMessages
        })
        setAiResponseIndex((prevIndex) => prevIndex + 1) // Increment the AI response index
        setIsTyping(false) // Hide typing indicator after response is added
      }, 5000)
    },
    [aiResponseIndex]
  )

  // Check if aiResponseIndex is equal to 8
  useEffect(() => {
    if (aiResponseIndex === 7) {
      addNextAIResponse()
    }
  }, [aiResponseIndex, addNextAIResponse])

  const renderMessage = (message: MessageType) => {
    if (message.type === 'user') {
      return (
        <motion.div
          initial={{
            opacity: 0,
            x: '-10',
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          style={{
            alignSelf: 'end',
          }}
        >
          <Stack key={message.id}>
            <h6>You</h6>
            <Flex
              direction="column"
              align="end"
              css={{ alignSelf: 'end' }}
              className="message"
            >
              {message.messageType === 'file' && (
                <Flex align="center" gutter="3" css={{ p: '.5rem' }}>
                  <BsFileEarmarkPdfFill color="#F15656" size="5rem" />
                  {message.message}
                </Flex>
              )}
              {message.messageType === 'text' && (
                <Flex direction="column" gutter="2">
                  {message.message}
                </Flex>
              )}
            </Flex>
          </Stack>
        </motion.div>
      )
    } else if (message.type === 'ai') {
      return (
        <motion.div
          initial={{
            opacity: 0,
            x: '10',
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
        >
          <Stack key={message.id}>
            <h6>Centrum AI</h6>
            <Flex
              direction="column"
              align="start"
              css={{ alignSelf: 'start' }}
              className="message"
            >
              <Flex direction="column" gutter="2">
                <p dangerouslySetInnerHTML={{ __html: message.message }}></p>
                {message.actions && (
                  <Flex direction="column" gutter="2">
                    {message.actions.map((action, index) => (
                      <ActionButton
                        key={index}
                        text={action}
                        onClick={() => handleActionClick(action)}
                      />
                    ))}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Stack>
        </motion.div>
      )
    }
  }

  const handleSaveAutomation = () => {
    setShowSequence(false)
    // Add the next AI response
    addNextAIResponse({
      automationMessage: `Great! Your automation sequence has been saved successfully. 🎉 This workflow will help you stay on top of supplier responses and streamline your order process.
      <br/>
      <br/>
      Here's what happens next: <br/> <br/>
      1. You'll be notified if a supplier responds within 24 hours. <br/> <br/>
      2. If a quote is favorable, we'll prepare a purchase order (PO) draft for you.
      <br/>
      <br/>
      Would you like to:
      `,
      actions: ['Start a new search', 'Review your saved workflows'],
    })
  }

  return (
    <StyleContent
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDragOver={(e) => {
        e.preventDefault()
        setIsDragging(true)
      }}
      onDrop={(e) => {
        e.preventDefault()
        setIsDragging(false)
        const droppedFile = e.dataTransfer.files[0]
        if (droppedFile) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              id: prevMessages.length + 1,
              type: 'user',
              message: droppedFile.name,
              messageType: 'file',
            },
          ])
          // setAiResponseIndex(12)
          addNextAIResponse()
        }
      }}
    >
      <Flex direction="column" className="box">
        <StyledUpload isDragging={isDragging} className="upload">
          {isDragging && (
            <label htmlFor="file-upload">Drop your file here</label>
          )}
        </StyledUpload>

        <Flex align="center" className="top" justify="between">
          <Flex align="center" gutterX="2">
            <img src={favicon} alt="" />
            <h4>
              {messages.length
                ? 'Supplier search for electric bikes in China'
                : 'Centrum AI'}
            </h4>
          </Flex>
          <RiCloseLine
            onClick={() => navigate('/dashboard')}
            size="2rem"
            color="#6a6a6a"
            style={{
              cursor: 'pointer',
            }}
          />
        </Flex>
        {!showSequence && (
          <>
            <div className="content">
              <div className="chat">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  className="atlas"
                  gutter="4"
                >
                  <img src={AIimage} alt="" />
                  <p className="welcome-text">
                    I'm Centrum AI, your procurement assistant. Here are some
                    example queries to explore my capabilities:
                  </p>
                  {!messages.length && (
                    <Flex
                      align="center"
                      justify="center"
                      wrap="wrap"
                      gutter="2"
                    >
                      <ActionButton
                        appearance="white"
                        text="Electric bikes from China"
                        onClick={() =>
                          handleActionClick('Electric bikes from China')
                        }
                      />
                      <ActionButton
                        appearance="white"
                        text="Upload and process an invoice for payment"
                        onClick={() =>
                          handleActionClick(
                            'Upload and process an invoice for payment'
                          )
                        }
                      />
                      <ActionButton
                        appearance="white"
                        text="Compare quotes from multiple suppliers for laptops"
                        onClick={() =>
                          handleActionClick(
                            'Compare quotes from multiple suppliers for laptops'
                          )
                        }
                      />
                      <ActionButton
                        appearance="white"
                        text="Review contract terms with a supplier"
                        onClick={() =>
                          handleActionClick(
                            'Review contract terms with a supplier'
                          )
                        }
                      />
                    </Flex>
                  )}
                </Flex>
                <Flex
                  direction="column"
                  justify="between"
                  gutter="4"
                  className="message-wrapper"
                >
                  {messages.map((message) => renderMessage(message))}
                  {isTyping && ( // Show typing indicator only if isTyping is true
                    <motion.div
                      initial={{
                        opacity: 0,
                        x: '10',
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                      }}
                      transition={{
                        delay: 0.5,
                      }}
                    >
                      <Stack className="blink">
                        <h6>Centrum AI</h6>
                        <Flex
                          direction="column"
                          align="start"
                          css={{ alignSelf: 'start' }}
                          className="message"
                        >
                          <Flex direction="column" gutter="2">
                            <div className="typing-indicator">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          </Flex>
                        </Flex>
                      </Stack>
                    </motion.div>
                  )}
                  <div ref={messagesEndRef} /> {/* Ref for auto-scrolling */}
                </Flex>

                {aiResponseIndex === 6 && (
                  <StyledButton
                    appearance="white"
                    css={{
                      margin: 'auto',
                    }}
                    onClick={() => setShowSequence(true)}
                  >
                    <PiMagicWandBold />
                    Set up a tracking alert for supplier responses
                  </StyledButton>
                )}
                {aiResponseIndex === 11 && (
                  <StyledButton
                    appearance="white"
                    css={{
                      margin: 'auto',
                    }}
                    onClick={() => setShowSequence(true)}
                  >
                    <PiMagicWandBold />
                    Set up a reminder for payment
                  </StyledButton>
                )}
              </div>
            </div>
            {/* Form for user input */}
            <form onSubmit={handleSubmit}>
              <Flex align="center" justify="between" className="footer">
                <input
                  type="text"
                  placeholder="How can I assist you today?"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <button type="submit">
                  <TbSend size="2.5rem" color="#aaa" />
                </button>
              </Flex>
            </form>
          </>
        )}
        {showSequence && (
          <AtlasAiSequence
            handleSaveAutomation={handleSaveAutomation}
            setShowSequence={setShowSequence}
          />
        )}
      </Flex>
    </StyleContent>
  )
}

type ActionButtonProps = {
  text: string
  appearance?: 'white'
  onClick: () => void // Adding onClick prop type
}

function ActionButton({ text, appearance, onClick }: ActionButtonProps) {
  return (
    <StyledButton appearance={appearance} onClick={onClick}>
      {text}
    </StyledButton>
  )
}
